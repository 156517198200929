import {
  AssignLicenseRequest,
  License,
  LicenseAssignResponse,
  LicenseAuditHistory,
  LicenseListRequest,
  LicenseListResponse,
  LicensePostRequest,
} from '../stores/types/licenses.interface';
import { API_KEYS, Api } from './base.api';
import { SCOPE_TYPE, providesListTag } from './utils';

export const licenseApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    fetchLicenseByLicenseKey: builder.query<
      License,
      { scope?: SCOPE_TYPE; companyId?: string; licenseKey?: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}/${payload.licenseKey}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: API_KEYS.LICENSE, id: result?.licenseKey },
      ],
    }),
    fetchLicenseList: builder.query<LicenseListResponse, LicenseListRequest>({
      query: (param) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}`,
        params: param,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        providesListTag(
          result?.entities.map((license) => ({ id: license.licenseKey })),
          API_KEYS.LICENSE,
        ),
    }),
    assignLicense: builder.mutation<
      LicenseAssignResponse,
      AssignLicenseRequest
    >({
      query: (request) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}`,
        params: {
          scope: request.licensePatchRequestUrlParam.scope,
          companyId: request.licensePatchRequestUrlParam.companyId,
          bulkOperation: request.licensePatchRequestUrlParam.bulkOperation,
        },
        body: request.licensePatchRequestBody,
        method: 'PATCH',
        toast: {
          successTrans: request.licensePatchRequestToast.isChargerDeployed
            ? 'assign_charger_success_msg'
            : 'assign_undeployed_charger_success_msg',
          failedTrans: 'assign_charger_failure_msg',
          values: {
            chargerName: request.licensePatchRequestToast.chargerName,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.LICENSE, id: 'LIST' },
        { type: API_KEYS.LICENSE, id: result?.response.licenseKey },
        { type: API_KEYS.CHARGERS, id: 'LIST' },
      ],
    }),

    addLicense: builder.mutation<
      License,
      { license: LicensePostRequest; userScope: string }
    >({
      query: ({ license, userScope }) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}`,
        params: { scope: userScope, companyId: license.companyId },
        method: 'POST',
        body: license,
        toast: {
          successTrans: 'license_add_license_success',
          failedTrans: 'license_add_license_fail',
          values: {
            numOfLicenses: license.numberOfLicenses,
          },
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.LICENSE, id: 'LIST' },
      ],
    }),
    fetchLicenseAuditHistoryByLicenseId: builder.query<
      LicenseAuditHistory[],
      { scope?: SCOPE_TYPE; companyId?: string; licenseId?: string }
    >({
      query: (payload) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}/${API_KEYS.LICENSE_AUDIT_HISTORY}/${payload.licenseId}`,
        params: { scope: payload.scope, companyId: payload.companyId },
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result
          ? result.map((auditHistory) => ({
              type: API_KEYS.LICENSE_AUDIT_HISTORY,
              id: auditHistory.licenseId,
            }))
          : [{ type: API_KEYS.LICENSE_AUDIT_HISTORY, id: 'LIST' }],
    }),

    deleteLicense: builder.mutation<void, { licenseKey: string }>({
      query: ({ licenseKey }) => ({
        url: `/internal/core/v1/${API_KEYS.LICENSE}/${licenseKey}`,
        method: 'DELETE',
        toast: {
          successTrans: 'license_delete_success',
          failedTrans: 'license_delete_fail',
          values: {
            licenseKey,
          },
        },
      }),
      invalidatesTags: (result, error, { licenseKey }) => [
        { type: API_KEYS.LICENSE, id: licenseKey },
        { type: API_KEYS.LICENSE, id: 'LIST' },
        { type: API_KEYS.CHARGERS, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchLicenseByLicenseKeyQuery,
  useFetchLicenseListQuery,
  useLazyFetchLicenseListQuery,
  useAssignLicenseMutation,
  useAddLicenseMutation,
  useFetchLicenseAuditHistoryByLicenseIdQuery,
  useDeleteLicenseMutation,
} = licenseApi;
