import { formatInTimeZone } from 'date-fns-tz';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  BoltCharging,
  CheckMark,
  ErrorWarningtraiangle,
  ExternalLink,
  Play2Start,
  RFIDCard,
  Vehicle as VehicleIcon,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import { useFetchChargerDetailQuery } from '../../services/charger.api';
import { SCOPE_TYPE } from '../../services/utils';
import { getWebAppUrlPrefix } from '../../stores/selectors/deployment.selector';
import { SESSION_TYPES } from '../../stores/types/sessions.interface';
import { convertToLocaleCurrency } from '../../utils/Currency.Util';
import {
  convertToDate,
  getDifferenceInMinutes,
  getISO8601TimezoneOffSetFromTimeZone,
  getLocale,
} from '../../utils/Date.Util';
import { displayEneryKwh } from '../../utils/Number.Util';
import { getConnectorAbbr, getConnectorIcon } from '../Charger/utils';
import {
  ColorType,
  Icon,
  Label,
  LabelType,
  Pill,
  Spinner,
  Timeline,
} from '../_ui';
import { SessionDetailData } from './SessionDetail.interface';
import SessionViaPaymentTerminal from './SessionViaPaymentTerminal.component';
import { handleTimeCalculation } from './utils';

export interface SessionDetailProps {
  sessionData: SessionDetailData;
}

const getIconProps = (chargingStatus: string) => {
  const chargingStatusValue = chargingStatus.toLowerCase();
  let icon;
  let color = ColorType.GREY6;
  switch (chargingStatusValue) {
    case 'start':
      icon = Play2Start;
      color = ColorType.POSITIVE1;
      break;
    case 'charging':
    case 'in_use':
    case 'preparing':
    case 'paused by charger':
    case 'paused by vehicle':
      icon = BoltCharging;
      color = ColorType.ACCENT1;
      break;
    case 'completed':
      icon = CheckMark;
      color = ColorType.POSITIVE1;
      break;
    case 'failed':
      icon = ErrorWarningtraiangle;
      color = ColorType.NEGATIVE1;
      break;
    default:
      icon = '';
      break;
  }
  return { icon, color };
};

const renderTitle = (title: string, status: any) => {
  const { icon, color } = getIconProps(status);
  return (
    <div className='flex flex-row gap-1'>
      <Icon src={icon} color={color} size={IconSize.SIZE_20x20} />
      <Label text={title} type={LabelType.BODY3} color={ColorType.BLACK} />
    </div>
  );
};

const renderContent = (date: Date, index: number, timeZone?: string) => {
  if (date) {
    return (
      <Label
        text={
          formatInTimeZone(
            date,
            timeZone || '',
            'LLL dd, h:mm a',
            getLocale(),
          ) + getISO8601TimezoneOffSetFromTimeZone(timeZone || '')
        }
        type={LabelType.BODY3}
        color={ColorType.GREY6}
      />
    );
  }
  if (index === 0) {
    return <Spinner />;
  }
  return null;
};

const checkSessionStatusForDivVisiblity = (status: any) => {
  if (
    status === 'FAILED' ||
    status === 'PREPARING' ||
    status === 'IN_PROGRESS'
  ) {
    return false;
  }
  return true;
};

export const SessionDetail = memo(({ sessionData }: SessionDetailProps) => {
  const { t } = useTranslation();

  const webAppUrlPrefix: any = useSelector(getWebAppUrlPrefix);

  const { data: chargerData } = useFetchChargerDetailQuery({
    scope: SCOPE_TYPE.COMPANY,
    id: sessionData.chargerId,
  });

  const portCount = useMemo(() => {
    return chargerData?.ports.length || 0;
  }, [chargerData]);

  const renderCostDiv = (amount: any, currency: any) => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('session_cost')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <div className='flex flex-col gap-y-1.5'>
          <Label
            text={convertToLocaleCurrency(amount || 0, currency)}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
          <div
            className='flex flex-row place-items-center gap-x-3 cursor-pointer'
            onClick={() => {
              window.open(
                `${webAppUrlPrefix}/receipts/${sessionData.receiptId}`,
              );
            }}
          >
            <Label
              text={t('view_receipt')}
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.DENIM}
            />
            <Icon
              src={ExternalLink}
              color={ColorType.DENIM}
              size={IconSize.SIZE_20x20}
              alt={t('icon_alt_open_in_new_window')}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEnergyUsedDiv = (kWhUsed: any) => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('session_kwh_used')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          text={displayEneryKwh(kWhUsed, t('kwh'))}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
    );
  };

  const renderDurationDiv = (endTime: any, startTime: any) => {
    const mins = getDifferenceInMinutes(
      convertToDate(endTime),
      convertToDate(startTime),
      { roundingMethod: 'round' },
    );

    return (
      <div className='flex flex-row'>
        <Label
          text={t('session_duration')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          text={endTime && startTime ? handleTimeCalculation(Number(mins)) : ''}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
    );
  };

  const renderEndTimeDiv = (endTime: any, timeZone: any) => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('session_end_time')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          text={
            endTime && timeZone
              ? formatInTimeZone(
                  endTime,
                  timeZone,
                  'LLL dd, yyyy h:mm a',
                  getLocale(),
                ) + getISO8601TimezoneOffSetFromTimeZone(timeZone)
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
    );
  };

  const renderStartTimeDiv = (startTime: any, timeZone: any) => {
    return (
      <div className='flex flex-row'>
        <Label
          text={t('session_start_time')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          text={
            startTime && timeZone
              ? formatInTimeZone(
                  startTime,
                  timeZone,
                  'LLL dd, yyyy h:mm a',
                  getLocale(),
                ) + getISO8601TimezoneOffSetFromTimeZone(timeZone)
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
    );
  };
  return (
    <div className='flex flex-col py-2 gap-5'>
      {sessionData.sessionStatus !== 'FAILED' &&
        renderStartTimeDiv(sessionData.startTime, sessionData.timeZone)}
      {checkSessionStatusForDivVisiblity(sessionData.sessionStatus) &&
        renderEndTimeDiv(sessionData.endTime, sessionData.timeZone)}
      {checkSessionStatusForDivVisiblity(sessionData.sessionStatus) &&
        renderDurationDiv(sessionData.endTime, sessionData.startTime)}
      {sessionData.userEmail && (
        <div className='flex flex-row'>
          <Label
            text={t('driver')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='basis-2/6'
          />
          <Label
            text={sessionData.userEmail}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        </div>
      )}
      <div className='flex flex-row'>
        <Label
          text={t('session_auth_type')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        {sessionData.authenticationType === SESSION_TYPES.PAYMENT_TERMINAL ? (
          <SessionViaPaymentTerminal sessionData={sessionData} />
        ) : (
          <Label
            text={t(sessionData.authenticationType)}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        )}
      </div>
      {sessionData.vehicleName && (
        <div className='flex flex-row'>
          <Label
            text={t('vehicle')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='basis-2/6'
          />
          {sessionData.isVehicleActive ||
          sessionData.isVehicleActive === undefined ? (
            <Pill
              label={sessionData.vehicleName}
              bgColor={ColorType.GREY2}
              iconLeft={VehicleIcon}
            />
          ) : (
            <Label
              text={`${sessionData.vehicleName} (${t('vehicles_deleted')})`}
              type={LabelType.BODY3}
              color={ColorType.GREY5}
            />
          )}
        </div>
      )}
      {sessionData.rfids?.length > 0 && (
        <div className='flex flex-row'>
          <Label
            text={t('vehicles_rfid')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
            className='basis-2/6'
          />
          <div className='flex flex-col gap-[4px]'>
            {sessionData.rfids.map((rfid) => {
              return <Pill iconLeft={RFIDCard} key={rfid} label={rfid} />;
            })}
          </div>
        </div>
      )}
      <div className='flex flex-row'>
        <Label
          text={t('charger')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Pill label={sessionData.charger} />
      </div>
      <div className='flex flex-row'>
        <Label
          text={t('session_connector')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <div className='flex flex-row gap-4'>
          {portCount > 1 && ( // BF-677 hide connector side for sinle port
            <Label
              text={sessionData.connectorSide}
              type={LabelType.BODY3}
              color={ColorType.GREY5}
            />
          )}
          <div className='flex flex-row gap-1'>
            <Icon
              src={getConnectorIcon(sessionData.connector)}
              color={ColorType.BLACK}
              size={IconSize.SIZE_20x20}
            />
            <Label
              text={getConnectorAbbr(sessionData.connector)}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
        </div>
      </div>
      <div className='flex flex-row'>
        <Label
          text={t('location')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          text={sessionData.location}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <div className='flex flex-row'>
        <Label
          text={t('session_address')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Label
          className='max-w-[305px]'
          text={sessionData.address}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      {checkSessionStatusForDivVisiblity(sessionData.sessionStatus) &&
        renderEnergyUsedDiv(sessionData.kwhUsed)}
      {checkSessionStatusForDivVisiblity(sessionData.sessionStatus) &&
        renderCostDiv(sessionData.cost, sessionData.currency)}
      <div className='flex flex-row'>
        <Label
          text={t('session_status_hisotry')}
          type={LabelType.LABEL_S}
          color={ColorType.GREY6}
          className='basis-2/6'
        />
        <Timeline
          data={sessionData.statusHistory || []}
          renderTitle={renderTitle}
          renderContent={renderContent}
        />
      </div>
    </div>
  );
});

// Adding display name to fix the eslint error:
// Error: Component definition is missing display name eslint react

SessionDetail.displayName = 'SessionDetail';
