export const SERVER_SIDE_CHARGER_STATUS = {
  ONLINE: 'ONLINE',
  COMING_SOON: 'COMING_SOON',
  OUT_OF_ORDER: 'OUT_OF_ORDER',
  CHARGING: 'CHARGING',
  OFFLINE: 'OFFLINE',
  SCHEDULED: 'SCHEDULED',
};
export const CHARGER_STATUS = {
  AVAILABLE: 'available',
  IN_USE: 'in_use',
  COMING_SOON: 'coming_soon',
  OUT_OF_ORDER: 'out_of_order',
  OFFLINE: 'offline',
  SCHEDULED: 'scheduled',
  PREPARING: 'preparing',
};

export const OCPP_STATUS = {
  CHARGING: 'charging',
  SUSPENDED_EV: 'suspended_ev',
  SUSPENDED_EVSE: 'suspended_evse',
};

export const OUT_OF_ORDER_PORT_OCPP_STATUS = {
  UNAVAILABLE: 'unavailable',
  FAULTED: 'faulted',
};

export const CONNECTOR_TYPES = {
  J1772: 'J1772',
  DUAL_J1772: 'DUAL_J1772',
  CCS: 'CCS',
  DUAL_CCS: 'DUAL_CCS',
  CHADEMO: 'CHAdeMO',
  CCS_CHADEMO: 'CCS + CHAdeMO',
  TYPE_2: 'Type 2',
  TYPE_2_CCS: 'Type 2 CCS',
  TYPE_2_CHADEMO_TYPE_2_CCS: 'Type 2/CHAdeMO/Type 2 CCS',
  TESLA: 'Tesla',
  NACS: 'NACS',
};

export const PRICING_SCHEDULED_TYPE = {
  PER_DAY: 'PER_DAY',
  SAME_EVERYDAY: 'SAME_EVERYDAY',
  WEEKDAYS_WEEKEND: 'WEEKDAYS_WEEKEND',
  TIERED: 'TIERED',
  FREE: 'FREE',
};

export const TEXT = {
  INDIVIDUAL: 'INDIVIDUAL',
  DOMAIN_NAME: 'DOMAIN_NAME',
  CUSTOM: 'CUSTOM',
  PERCENTAGE: 'PERCENTAGE',
  CHARGER: 'CHARGER',
  FREE: 'FREE',
  FULFILLED: 'fulfilled',
  ALL: 'all',
  SIMULATED: 'SIMULATED',
};

export const WEEKDAYS: any = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const WEEKDAY_WEEKEND = {
  1: 'Weekday',
  2: 'Weekend',
};

export const CHARGER_FLAG = {
  UNDER_MAINTENANCE: 'under_maintenance',
};

export const ILLEGAL_KEYWORDS = ['register', 'app', 'login', '404'];

export const PM_CHARGER_STATUS = {
  INVALID: 'INVALID',
  VALID: 'VALID',
};

export const PM_WARNING_TYPE = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};

export const PM_ERROR_CODE: {
  [key: string]: {
    label: string;
    type: string;
    hoverTooltip: string;
    clickTooltip: string;
  };
} = {
  'ERR-PM-1002': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.ERROR,
    hoverTooltip: 'err_pm_1002',
    clickTooltip: 'err_pm_1002',
  },
  'ERR-PM-1003': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1003',
    clickTooltip: 'err_pm_1003',
  },
  'ERR-PM-1004': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1004',
    clickTooltip: 'err_pm_1004',
  },
  'ERR-PM-1005': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1005',
    clickTooltip: 'err_pm_1005',
  },
  'ERR-PM-1006': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1006',
    clickTooltip: 'err_pm_1006',
  },
  'ERR-PM-1007': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1007',
    clickTooltip: 'err_pm_1007',
  },
  'ERR-PM-1008': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1008',
    clickTooltip: 'err_pm_1008',
  },
  'ERR-PM-1009': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1009',
    clickTooltip: 'err_pm_1009',
  },
  'ERR-PM-1010': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1010',
    clickTooltip: 'err_pm_1010',
  },
  'ERR-PM-1011': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.ERROR,
    hoverTooltip: 'err_pm_1011',
    clickTooltip: 'err_pm_1011',
  },
  'ERR-PM-1012': {
    label: 'pm_missing_charger_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1012',
    clickTooltip: 'err_pm_1012',
  },
  'ERR-PM-1013': {
    label: 'pm_missing_port_info',
    type: PM_WARNING_TYPE.ERROR,
    hoverTooltip: 'err_pm_1013',
    clickTooltip: 'err_pm_1013',
  },
  'ERR-PM-1014': {
    label: 'pm_missing_port_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1014',
    clickTooltip: 'err_pm_1014',
  },
  'ERR-PM-1015': {
    label: 'pm_missing_port_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1015',
    clickTooltip: 'err_pm_1015',
  },
  'ERR-PM-1016': {
    label: 'pm_missing_port_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1016',
    clickTooltip: 'err_pm_1016',
  },
  'ERR-PM-1017': {
    label: 'pm_missing_port_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_1017',
    clickTooltip: 'err_pm_1017',
  },
  'ERR-PM-1018': {
    label: 'pm_power_profile_error',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'pm_power_profile_error_tooltip',
    clickTooltip: 'pm_power_profile_error_tooltip',
  },
  'ERR-PM-000': {
    label: 'pm_unknown_error_info',
    type: PM_WARNING_TYPE.WARNING,
    hoverTooltip: 'err_pm_0000',
    clickTooltip: 'err_pm_0000',
  },
};
