import posthog from 'posthog-js';

// Event name constants
export enum AnalyticsEvent {
  // Company Events
  COMPANY_INVITE_SEND_EVENT = 'company_invite_send_clicked',

  // Access Events
  ACCESS_EDIT_EVENT = 'access_edit_clicked',

  // Pricing Events
  PRICING_EDIT_EVENT = 'pricing_rule_edit_save_clicked',

  // Report Events
  REPORT_GENERATE_EVENT = 'report_generate_clicked',

  // Charger Events
  CHARGER_ADD_EVENT = 'charger_add_save_clicked',
  CHARGER_REBOOT_EVENT = 'charger_reboot_clicked',
  CHARGER_MOVE_EVENT = 'charger_move_confirm_clicked',
  CHARGER_DETAILS_UPDATE_EVENT = 'charger_details_save_clicked',
  CHARGER_DETAILS_UPDATE_CANCELLED_EVENT = 'charger_details_cancel_clicked',

  // Discount Events
  DISCOUNT_ADD_EVENT = 'discount_add_save_clicked',

  // Payout Events
  PAYOUT_EXPORT_EVENT = 'payout_export_clicked',

  // License Events
  LICENSE_GENERATE_EVENT = 'license_generate_save_clicked',
}

// Type for properties that can be passed with events
export type AnalyticsProperties = {
  [key: string]: string | number | boolean | null | undefined;
};

// Main analytics capture function
export const captureAnalyticsEvent = (
  event: AnalyticsEvent,
  properties?: AnalyticsProperties,
): void => {
  try {
    posthog.capture(event, properties);
  } catch (error) {
    console.error(`Failed to capture analytics event: ${event}`, error);
  }
};
