import { monthsToYears } from 'date-fns';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ChargerEV, ErrorWarningtraiangle, More } from '../../assets/icons';
import { NUMBER } from '../../constant/Number.constant';
import { useCharger } from '../../hooks/useChargers';
import { useLicense } from '../../hooks/useLicense';
import { License } from '../../stores/types';
import { convertToDate, formatDate } from '../../utils/Date.Util';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Grid,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  Pill,
  useGlobalModalContext, Divider,
} from '../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import LicenseDetails from './LicenseDetails.component';
import LicenseStatus from './LicenseStatus.component';
import NoLicense from './NoLicense.component';
import AssignLicense from './assign-license/AssignLicenseComponent';
import { useDeleteLicenseMutation } from '../../services/license.api';
import {
  LICENSE_STATUS,
  getReadableLicense,
  getRemainingDays,
  isNoDurationType,
} from './utils';

const LicensesGrid = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { getChargerNameById } = useCharger();

  const {
    licenseFilterquery,
    licensesData,
    handleLoadPage,
    licensesDataCount,
    isLicenseLoading,
  } = useLicense();

  const getRemainings = (expirationDate: Date, status: string) => {
    if (status.toLowerCase() === LICENSE_STATUS.EXPIRED) {
      return t(LICENSE_STATUS.EXPIRED);
    }
    const { years, days } = getRemainingDays(new Date(), expirationDate);
    const noOfYearsDays = years > 0 ? `${years}y ${days}d` : `${days}d`;
    return t('license_grid_remaining', { noOfYearsDays });
  };

  const ROW_PER_PAGE = NUMBER.TWENTY;

  const handleViewLicenseClick = useCallback(
    (row: License) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('license_details'),
        height: 'fit-content',
        bodyStyleClass: 'flex-grow',
        onRenderBody: () => <LicenseDetails license={row} />,
      });
    },
    [showModal, t],
  );
  const showTextWithTooltip = (text: string) => {
    return (
      <div className='flex items-center'>
        <Tooltip>
          <TooltipTrigger>
            <Label
              isLoading={isLicenseLoading}
              className='max-w-[120px] whitespace-nowrap truncate'
              text={text}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </TooltipTrigger>
          <TooltipContent style={{ maxWidth: 'fit-content' }}>
            <Label
              text={text || ''}
              type={LabelType.BODY3}
              color={ColorType.WHITE}
            />
          </TooltipContent>
        </Tooltip>
      </div>
    );
  };

  const handleAssignLicense = useCallback(
    (row: License) => {
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('license_assign'),
        height: 'max-content',
        bodyStyleClass: 'flex-grow',
        onRenderBody: () => <AssignLicense license={row} />,
        shouldCloseOnOverlayClick: false,
      });
    },
    [showModal, t],
  );

  const handleDeleteLicense = (row: License) => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('license_delete_warn_title'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.NEGATIVE,
      message: (
        <Trans
          i18nKey={t('license_delete_warn_message')}
          components={{
            bold: <strong />,
          }}
        />
      ),
      mutationHook: useDeleteLicenseMutation,
      mutationParams: {
        licenseKey: row.licenseKey,
      },
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          disableDelayTime: 3000,
          disableDelayLabel: t('wait_3_sec'),
          className: 'min-w-fit w-20',
          isSubmit: true,
          dataTestId: 'delete-license-button',
        },
      ],
      onClose: (qResult: any) => {
        if (qResult?.isSuccess) {
          handleLoadPage(1);
        }
      },
      shouldCloseOnOverlayClick: false,
    });
  };

  const getColumnTitle = () => {
    const columnData = [
      {
        key: 'licenseKey',
        title: t('license_key'),
        component: (row: any) => {
          return showTextWithTooltip(row.licenseKey);
        },
      },
      {
        key: 'status',
        title: t('status'),
        component: (row: any) => (
          <LicenseStatus isLoading={isLicenseLoading} status={row.status} />
        ),
      },
      {
        key: 'assignedCharger',
        title: t('charger'),
        class: 'min-w-[120px]',
        component: (row: any) => {
          return (
            (getChargerNameById(row.assignedCharger) || isLicenseLoading) && (
              <Pill
                isLoading={isLicenseLoading}
                iconLeft={ChargerEV}
                label={getChargerNameById(row.assignedCharger)}
              />
            )
          );
        },
      },
      {
        key: 'type',
        title: t('license_type'),
        class: 'w-[60px]',
        component: (row: any) => (
          <Label
            className='w-[60x] items-center'
            isLoading={isLicenseLoading}
            text={t(getReadableLicense(row.type))}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        ),
      },
      {
        key: 'ports',
        title: t('ports'),
        component: (row: any) => (
          <Label
            isLoading={isLicenseLoading}
            text={row.ports}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        ),
      },
      {
        key: 'includes4G',
        title: t('license_inc_4g'),
        component: (row: any) => (
          <Label
            isLoading={isLicenseLoading}
            text={t(row.includes4G ? 'yes' : 'no')}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
        ),
      },
      {
        key: 'duration',
        title: t('duration'),
        component: (row: any) => {
          return (
            <div className='overflow-hidden text-ellipsis items-center'>
              {isNoDurationType(row?.type) ? (
                <Label
                  isLoading={isLicenseLoading}
                  text={t('duration_not_available')}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              ) : (
                <Label
                  isLoading={isLicenseLoading}
                  text={t('license_grid_duration', {
                    number: monthsToYears(row.duration),
                  })}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              )}
            </div>
          );
        },
      },
      {
        key: 'remaining',
        title: t('license_remaining'),
        component: (row: any) => {
          return (
            <div className='overflow-hidden text-ellipsis items-center'>
              {row.expirationDate && !isNoDurationType(row?.type) && (
                <Label
                  isLoading={isLicenseLoading}
                  text={getRemainings(row.expirationDate, row.status)}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              )}
            </div>
          );
        },
      },
      {
        key: 'expirationDate',
        title: t('expires'),
        component: (row: any) => {
          return (
            <div className='overflow-hidden text-ellipsis items-center w-[100px]'>
              {row.expirationDate && !isNoDurationType(row?.type) && (
                <Label
                  isLoading={isLicenseLoading}
                  text={formatDate(
                    convertToDate(row.expirationDate),
                    'MMM d, yyyy',
                  )}
                  type={LabelType.BODY3}
                  color={ColorType.BLACK}
                />
              )}
            </div>
          );
        },
      },
      {
        key: 'orderId',
        title: t('order_id'),
        component: (row: any) => {
          return showTextWithTooltip(row.orderId);
        },
      },
      {
        key: 'action',
        title: '',
        component: (row: License) => {
          return (
            <div className='flex justify-end items-start'>
              <Menu
                placement='bottom-end'
                icon={More}
                iconAlt={t('icon_alt_more_options')}
              >
                {row.status?.toLowerCase() === LICENSE_STATUS.UNASSIGNED.toLowerCase() && (
                  <MenuItem
                    label={t('license_assign')}
                    dataTestId="license_assign"
                    onClick={() => handleAssignLicense(row)}
                  />
                )}
                <MenuItem
                  label={t('view_license_details')}
                  dataTestId='view-license-details'
                  onClick={() => handleViewLicenseClick(row)}
                />
                <Divider className='mx-2' margin='8px' />
                <MenuItem
                  label={t('delete')}
                  labelType={LabelType.BODY3}
                  labelColor={ColorType.NEGATIVE}
                  onClick={() => handleDeleteLicense(row)}
                  dataTestId='license-delete-button'
                />
              </Menu>
            </div>
          );
        },
      },
    ];

    return columnData;
  };

  return (
    <div className='overflow-hidden mt-2'>
      {licensesDataCount || isLicenseLoading ? (
        <Grid
          primaryKey='id'
          pageIndex={licenseFilterquery.page}
          loadPage={handleLoadPage}
          columns={getColumnTitle()}
          data={licensesData}
          totalPage={Math.ceil(licensesDataCount / ROW_PER_PAGE)}
          isLoading={isLicenseLoading}
          translateClass='translate-x-2.5'
        />
      ) : (
        <NoLicense isLoading={isLicenseLoading} isDataExists />
      )}
    </div>
  );
};

export default LicensesGrid;
