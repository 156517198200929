import { monthsToYears } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../hooks/useCompany';
import { useFetchLicenseByLicenseKeyQuery } from '../../../services/license.api';
import { Charger } from '../../../stores/types';
import { formatDate } from '../../../utils/Date.Util';
import {
  LICENSE_STATUS,
  isNoDurationType,
  daysDifferenceText,
  getReadableLicense,
} from '../../Licences/utils';
import { ColorType, Label, LabelType } from '../../_ui';
import LicenseLineItem from './LicenseLineItem.component';

interface IProps {
  charger: Charger;
  isLoading: boolean;
}
const ChargersLicenseDetails = ({ charger, isLoading }: IProps) => {
  const { t } = useTranslation();
  const { userScope } = useCompany();

  const { license } = useFetchLicenseByLicenseKeyQuery(
    {
      scope: userScope!,
      companyId: charger.ownerId,
      licenseKey: charger.licenseKey,
    },
    {
      skip: !charger.licenseKey,
      selectFromResult: (endPoint) => ({
        license: endPoint.data ? endPoint.data : null,
      }),
    },
  );

  const portKeys = useMemo(() => {
    if (!license?.ports || license.ports === 1) return null;

    return Array.from({ length: license.ports }, (_, index) => (
      <Label
        key={index}
        text={`${t('port')} ${index + 1} key: ${license.licenseKey}.${
          index + 1
        }`}
        type={LabelType.BODY4}
        color={ColorType.GREY6}
      />
    ));
  }, [license?.licenseKey, license?.ports]);

  const formatRemainingDays = (endDate: string | Date) => {
    if (license?.status?.toLowerCase() === LICENSE_STATUS.EXPIRED) {
      return t('expired_license_days', {
        noOfYearsDays: daysDifferenceText(true, endDate, t),
      });
    }
    return t('license_remaining_days', {
      noOfYearsDays: daysDifferenceText(false, endDate, t),
    });
  };

  const renderLicenseKeyValue = () => (
    <div className='flex flex-col gap-0.5'>
      <Label
        text={license?.licenseKey}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
        isLoading={isLoading}
        skeletonWidth='w-52'
        skeletonHeight='h-5'
      />
      {portKeys}
    </div>
  );

  const renderLicenseKey = () => (
    <LicenseLineItem
      isLoading={isLoading}
      label={t('license_key')}
      value={renderLicenseKeyValue()}
    />
  );

  const renderLicenseDuration = () => {
    const licenseDurationText = license
      ? t('license_duration_year_license', {
          duration: monthsToYears(license?.duration),
        })
      : '';
    return (
      <LicenseLineItem
        label={t('license_duration')}
        value={licenseDurationText}
        isLoading={isLoading}
      />
    );
  };

  const licenseExpiresValue = () => {
    const expiryDate = license?.expirationDate
      ? formatDate(new Date(license.expirationDate))
      : '';
    return (
      <div className='flex flex-col gap-0.5'>
        <Label
          text={expiryDate}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          isLoading={isLoading}
          skeletonWidth='w-52'
          skeletonHeight='h-5'
        />
        <Label
          text={
            license?.expirationDate
              ? formatRemainingDays(license.expirationDate)
              : ''
          }
          type={LabelType.BODY3}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const renderLicenseExpires = () => (
    <LicenseLineItem
      label={t('license_expires')}
      isLoading={isLoading}
      value={licenseExpiresValue()}
    />
  );

  const licenseType = useMemo(() => {
    return license?.type ? getReadableLicense(license?.type) : '';
  }, [license?.type]);

  const renderLicenseType = () => (
    <LicenseLineItem
      label={t('license_type_placeholder')}
      isLoading={isLoading}
      value={
        license?.type ? `${t(licenseType)}, ${license?.ports}-${t('port')}` : ''
      }
    />
  );

  return (
    <>
      {renderLicenseKey()}
      {renderLicenseType()}
      {!isNoDurationType(licenseType) && renderLicenseDuration()}
      {!isNoDurationType(licenseType) && renderLicenseExpires()}
    </>
  );
};

export default ChargersLicenseDetails;
