import monthsToYears from 'date-fns/monthsToYears';
import { useTranslation } from 'react-i18next';
import { Calendar, ChargerEV, J1772, Wifi } from '../../../assets/icons';
import { License } from '../../../stores/types';
import { Card, ColorType, Label, LabelType, Pill } from '../../_ui';
import { isNoDurationType, getReadableLicense } from '../utils';

interface IProps {
  licenseDetails: License;
}
const AssignLicenseDetails = ({ licenseDetails }: IProps) => {
  const { t } = useTranslation();
  const assignLicenseItems = (label: string, value = '') => {
    return (
      <div className='flex pb-1'>
        <Label
          text={`${t(label)}:`}
          type={LabelType.LABEL_S}
          color={ColorType.BLACK}
          className='mr-1'
        />
        <Label text={value} type={LabelType.BODY3} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderPill = (icon: string, label: string) => {
    return <Pill iconLeft={icon} label={label} />;
  };
  return (
    <Card className='border border-grey3 rounded pt-2 pb-1 px-2'>
      <div className='flex justify-between pb-3'>
        <Label
          text={t('license_details')}
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
        />
        <div className='flex gap-2'>
          {renderPill(ChargerEV, t(getReadableLicense(licenseDetails.type)))}
          {renderPill(J1772, `${licenseDetails.ports}-${t('port')}`)}
          {licenseDetails.includes4G && renderPill(Wifi, '4G')}
          {!isNoDurationType(licenseDetails.type) &&
            renderPill(Calendar, `${monthsToYears(licenseDetails.duration)}y`)}
        </div>
      </div>
      {assignLicenseItems('license_key', licenseDetails.licenseKey)}
      {assignLicenseItems('order_id', licenseDetails.orderId)}
      {assignLicenseItems('order_note', licenseDetails?.orderNote)}
    </Card>
  );
};

export default AssignLicenseDetails;
