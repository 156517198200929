import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AnalyticsEvent, captureAnalyticsEvent } from '../../constant/Events';
import { useAuth } from '../../hooks';
import { useCompany } from '../../hooks/useCompany';
import { useFetchCompanyDetailQuery } from '../../services/company.api';
import { useSendDelegationInvitesMutation } from '../../services/invite.api';
import { getWhiteLabellingCompanyName } from '../../stores/selectors/env.selector';
import { Company } from '../../stores/types';
import { Button, ButtonSize, ButtonType } from '../_ui';
import { Form } from '../_ui/Form.component';
import { useGlobalModalContext } from '../_ui/modal/GlobalModal.component';
import InviteUser from './InviteUser/InviteUser.component';
import { getCompanyIdsFromCompanies } from './InviteUser/utils';

interface IProps {
  triggerFetchInvites: Function;
}

export const TeamUserEditor = memo(({ triggerFetchInvites }: IProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const { hideModal } = useGlobalModalContext();
  const [users, setUsers] = useState<Array<string>>([]);
  const whiteLabelCompanyName: any = useSelector(getWhiteLabellingCompanyName);

  const [selectedCompany, setSelectedCompany] = useState<Array<Company>>([]);

  const [createInvite, inviteQueryResult] = useSendDelegationInvitesMutation();

  const selectedComapnyId = auth.user.attributes.profile?.lastActiveCompanyId;

  const { userScope } = useCompany();
  const { data: company } = useFetchCompanyDetailQuery({
    id: selectedComapnyId,
    scope: userScope!,
  });

  useEffect(() => {
    if (inviteQueryResult.isSuccess) {
      hideModal();
      triggerFetchInvites(company?.adminUserGroupId);
    }
  }, [
    inviteQueryResult.isSuccess,
    hideModal,
    triggerFetchInvites,
    company?.adminUserGroupId,
  ]);

  const handleSaveClick = () => {
    if (company?.adminUserGroupId) {
      captureAnalyticsEvent(AnalyticsEvent.COMPANY_INVITE_SEND_EVENT);
      createInvite({
        emails: users,
        companyIds: getCompanyIdsFromCompanies(selectedCompany),
      });
    }
  };

  const isButtonEnabled = users.length && selectedCompany.length;

  const renderInvite = () => (
    <Form onSubmit={handleSaveClick} queryResult={inviteQueryResult}>
      <InviteUser
        users={users}
        setUsers={setUsers}
        selectedComapnyId={selectedComapnyId}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
      <div className='flex flex-col pt-[16px] pb-[20px] sticky bottom-0 bg-white'>
        <Button
          label={t('send')}
          type={ButtonType.PRIMARY}
          disabled={!isButtonEnabled}
          size={ButtonSize.SMALL_FULL}
          isSumbit
          dataTestId='sendInviteButton'
        />
      </div>
    </Form>
  );

  return <div className='w-full h-full'>{renderInvite()}</div>;
});
