import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { useCompany } from '../../../hooks/useCompany';
import { useAssignLicenseMutation } from '../../../services/license.api';
import { SCOPE_TYPE } from '../../../services/utils';
import { License } from '../../../stores/types';
import {
  AssignLicenseRequest,
  AssignLicenseRequestToast,
  LICENSE_STATUS,
  LicensePatchRequestBody,
  LicensePatchRequestUrlParam,
} from '../../../stores/types/licenses.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../../_ui';
import { Form } from '../../_ui/Form.component';
import AssignLicenseDetails from './AssignLicenseDetails.conponent';
import SelectCharger, {
  LicenseChargerDropdownItem,
} from './SelectCharger.component';

interface IProps {
  license: License;
}
const AssignLicense = ({ license }: IProps) => {
  const [selectedChargerDropdownItem, setSelectedChargerDropdownItem] =
    useState<LicenseChargerDropdownItem>();
  const { t } = useTranslation();
  const [assignLicense, queryResult] = useAssignLicenseMutation();
  const { hideModal } = useGlobalModalContext();
  const { userScope } = useCompany();

  const save = () => {
    const requestBody: LicensePatchRequestBody = {
      licenseKey: license.licenseKey,
      assignedCharger: selectedChargerDropdownItem?.id,
      status: LICENSE_STATUS.ASSIGNED,
    };

    const urlParams: LicensePatchRequestUrlParam = {
      scope: userScope || SCOPE_TYPE.COMPANY,
      companyId: license.companyId,
      bulkOperation: false,
    };

    const toastValues: AssignLicenseRequestToast = {
      chargerName: selectedChargerDropdownItem?.label || '',
      isChargerDeployed: selectedChargerDropdownItem?.isDeployed || false,
    };

    const licensePatchRequest: AssignLicenseRequest = {
      licensePatchRequestBody: requestBody,
      licensePatchRequestUrlParam: urlParams,
      licensePatchRequestToast: toastValues,
    };
    assignLicense(licensePatchRequest);
  };

  const undeployedChargerAssignmentWarning = () => (
    <div className='my-5 w-full p-2 bg-grey0 border border-1 border-grey3 rounded-lg'>
      <div className='flex'>
        <Icon src={Info} color={ColorType.GREY6} size={IconSize.SIZE_24x24} />
        <Label
          className='ml-2'
          text={t('undeployed_charger_license_warning_msg')}
          type={LabelType.BODY4}
          color={ColorType.GREY6}
        />
      </div>
    </div>
  );

  const handleChargerSelect = useCallback(
    (selectedItem: LicenseChargerDropdownItem) => {
      setSelectedChargerDropdownItem(selectedItem);
    },
    [],
  );

  // The Boolean() function converts the value to a boolean,
  // returning true if selectedChargerDropdownItem exists and is not null/undefined,
  // and false otherwise
  const onSaveValidation = () => Boolean(selectedChargerDropdownItem);

  return (
    <Form
      onSubmit={save}
      queryResult={queryResult}
      onQuerySuccess={() => hideModal()}
      onQueryFailed={() => hideModal()}
    >
      <AssignLicenseDetails licenseDetails={license} />
      <div className='mt-4 mb-2'>
        <Label
          text={t('charger')}
          type={LabelType.LABEL_M}
          color={ColorType.BLACK}
        />
      </div>
      <SelectCharger
        license={license}
        selectedCharger={
          selectedChargerDropdownItem ? selectedChargerDropdownItem.id : ''
        }
        handleSelectedCharger={handleChargerSelect}
      />
      {selectedChargerDropdownItem &&
        selectedChargerDropdownItem.isDeployed === false &&
        undeployedChargerAssignmentWarning()}
      <div className='flex flex-col mt-6 pb-[20px] sticky bottom-0 bg-white'>
        <Button
          label={t('save')}
          type={ButtonType.PRIMARY}
          size={ButtonSize.MEDIUM_FULL}
          disabled={!onSaveValidation()}
          isSumbit
        />
      </div>
    </Form>
  );
};

export default AssignLicense;
