/* eslint-disable react/jsx-wrap-multilines */
import { monthsToYears } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChargerEV } from '../../assets/icons';
import { USER_ROLE, useAuth } from '../../hooks';
import { useCharger } from '../../hooks/useChargers';
import { useCompany } from '../../hooks/useCompany';
import { useFetchLicenseAuditHistoryByLicenseIdQuery } from '../../services/license.api';
import { License } from '../../stores/types';
import { convertToDate, formatDate } from '../../utils/Date.Util';
import { ColorType, Pill, Spinner, Timeline } from '../_ui';
import { Label, LabelType } from '../_ui/Label.component';
import LicenseDetailLabelPair from './LicenseDetailLabelPair.component';
import LicenseStatus from './LicenseStatus.component';
import {
  LICENSE_STATUS,
  daysDifferenceText,
  generateLicenseAuditHistory,
  getReadableLicense,
  isNoDurationType,
} from './utils';

interface LicenseDetailsProps {
  license: License;
}

const LicenseDetails = React.memo(({ license }: LicenseDetailsProps) => {
  const { t } = useTranslation();
  const { getChargerNameById } = useCharger();
  const { companies, companyId, userScope } = useCompany();
  const auth = useAuth();

  const portKeys = useMemo(() => {
    if (!license?.ports || license.ports === 1) return null;

    return Array.from({ length: license.ports }, (_, index) => (
      <Label
        key={index}
        text={`Port ${index + 1} key: ${license.licenseKey}.${index + 1}`}
        type={LabelType.BODY4}
        color={ColorType.GREY6}
      />
    ));
  }, [license?.licenseKey, license?.ports]);

  const distributingCompany = useMemo(() => {
    if (!companies) return null;

    const currentCompany = companies.find(
      (company) => company.id === companyId,
    );

    const channelPartnerId = currentCompany?.businessInfo?.channelPartnerId;
    return channelPartnerId
      ? companies.find((company) => company.id === channelPartnerId)
      : null;
  }, [companyId, companies]);

  const licenseType = useMemo(() => {
    return getReadableLicense(license.type);
  }, [license.type]);

  const { licenseAuditHistories } = useFetchLicenseAuditHistoryByLicenseIdQuery(
    {
      scope: userScope!,
      companyId: license.companyId,
      licenseId: license.licenseId,
    },
    {
      skip: auth.role !== USER_ROLE.SUPPORT,
      selectFromResult: (endPoint) => ({
        licenseAuditHistories: endPoint.data ? endPoint.data : [],
      }),
    },
  );

  const licenseHistoryData = useMemo(
    () =>
      generateLicenseAuditHistory(licenseAuditHistories, getChargerNameById) ||
      [],
    [licenseAuditHistories, getChargerNameById],
  );

  const renderTitle = (title: string, status: any) => {
    return (
      <div className='flex flex-row gap-1'>
        <Label text={title} type={LabelType.BODY3} color={ColorType.BLACK} />
      </div>
    );
  };

  const renderContent = (
    date: Date,
    index: number,
    timeZone?: string,
    updatedBy?: string,
  ) => {
    if (date) {
      return (
        <div>
          <Label
            text={date.toLocaleString('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
            type={LabelType.BODY3}
            color={ColorType.BLACK}
          />
          {updatedBy !== 'SYSTEM' && (
            <Label
              text={t('license_updated_by', {
                updatedByUser: updatedBy,
              })}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          )}
        </div>
      );
    }
    if (index === 0) {
      return <Spinner />;
    }
    return null;
  };

  return (
    <div className='flex flex-col pb-5'>
      <LicenseDetailLabelPair
        label={t('license_key')}
        value={
          <div className='flex flex-col gap-y-1'>
            <Label
              text={license.licenseKey}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
            {portKeys}
          </div>
        }
        height={license?.ports && license.ports > 1 ? 'h-[84px]' : 'h-10'}
        alignItems='items-start'
      />
      <LicenseDetailLabelPair label={t('order_id')} value={license.orderId} />
      <LicenseDetailLabelPair
        label={t('distributing_company')}
        value={distributingCompany?.name}
      />
      <LicenseDetailLabelPair
        label={t('order_note')}
        value={license?.orderNote}
      />
      <LicenseDetailLabelPair
        label={t('license_status')}
        value={<LicenseStatus status={license.status} isLoading={false} />}
      />
      <LicenseDetailLabelPair
        label={t('assigned_charger')}
        value={
          license.assignedCharger && (
            <Pill
              iconLeft={ChargerEV}
              label={getChargerNameById(license.assignedCharger)}
            />
          )
        }
      />
      <LicenseDetailLabelPair
        label={t('license_type')}
        value={t(licenseType)}
      />
      <LicenseDetailLabelPair
        label={t('port_count_for_license')}
        value={license?.ports}
      />
      <LicenseDetailLabelPair
        label={t('license_includes_4g')}
        value={license.includes4G ? t('yes') : t('no')}
      />
      <LicenseDetailLabelPair
        label={t('duration')}
        value={
          isNoDurationType(license.type)
            ? t('duration_not_available')
            : t('license_grid_duration', {
                number: monthsToYears(license.duration),
              })
        }
      />
      <LicenseDetailLabelPair
        label={t('expires')}
        alignItems='items-start'
        height='h-13'
        value={
          isNoDurationType(license.type) ? (
            t('license_does_not_expire')
          ) : (
            <div className='flex flex-col gap-y-1'>
              {license?.expirationDate && (
                <>
                  <Label
                    text={formatDate(
                      convertToDate(license.expirationDate),
                      'MMM d, yyyy',
                    )}
                    type={LabelType.BODY3}
                    color={ColorType.BLACK}
                  />
                  <Label
                    text={
                      license.status?.toLowerCase() === LICENSE_STATUS.EXPIRED
                        ? t('expired_license_days', {
                            noOfYearsDays: daysDifferenceText(
                              true,
                              license?.expirationDate,
                              t,
                            ),
                          })
                        : t('license_remaining_days', {
                            noOfYearsDays: daysDifferenceText(
                              false,
                              license?.expirationDate,
                              t,
                            ),
                          })
                    }
                    type={LabelType.BODY4}
                    color={ColorType.GREY6}
                  />
                </>
              )}
            </div>
          )
        }
      />
      <LicenseDetailLabelPair
        label={t('license_history')}
        alignItems='items-start'
        value={
          licenseHistoryData.length > 0 && (
            <Timeline
              data={licenseHistoryData}
              renderTitle={renderTitle}
              renderContent={renderContent}
            />
          )
        }
      />
    </div>
  );
});

LicenseDetails.displayName = 'LicenseDetails';
export default LicenseDetails;
