/* eslint-disable @typescript-eslint/ban-types */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchChargersQuery } from '../../../services/charger.api';
import { SCOPE_TYPE } from '../../../services/utils';
import { Charger, License } from '../../../stores/types';
import { ColorType, Dropdown, LabelType } from '../../_ui';
import { getLicenseLevelType, LICENSE_TYPES } from '../utils';

interface IProps {
  license: License;
  selectedCharger: string;
  handleSelectedCharger: Function;
}

export interface LicenseChargerDropdownItem {
  id: string;
  label: string;
  selected: boolean;
  isDeployed: boolean;
}

const SelectCharger = ({
  license,
  selectedCharger,
  handleSelectedCharger,
}: IProps) => {
  const { t } = useTranslation();

  const queryParams = useMemo(() => {
    const baseParams = { scope: SCOPE_TYPE.COMPANY };

    if (!license?.type) {
      return baseParams;
    }

    const levelType = getLicenseLevelType(license.type);

    // For HOME and ESSENTIALS licenses, show all charger types
    if (
      levelType === LICENSE_TYPES.HOME ||
      levelType === LICENSE_TYPES.ESSENTIALS
    ) {
      return baseParams;
    }

    // For other license types, filter by matching charger level type
    return {
      ...baseParams,
      'filter_eq[type]': levelType,
    };
  }, [license?.type]);

  const { data: chargers } = useFetchChargersQuery(queryParams);

  const chargersDropdownList: LicenseChargerDropdownItem[] = useMemo(() => {
    if (!chargers?.entities) return [];
    return chargers.entities
      .filter((charger: Charger) => !charger.licenseKey) // Filter out chargers that already have a license key
      .map((charger: Charger) => {
        return {
          id: charger.id,
          label: charger?.name || '',
          selected: charger.id === selectedCharger,
          isDeployed: charger.deploymentInfo?.deployed || false,
        };
      });
  }, [selectedCharger, chargers?.entities]);

  return (
    <div className='w-full'>
      <Dropdown
        placeholder={t('pm_select_charger')}
        placeholderLabelType={LabelType.BODY3_G4}
        headerWidth='100%'
        items={chargersDropdownList}
        onItemClick={(selectedItem: any) => handleSelectedCharger(selectedItem)}
        placement='top-start'
        contentDivWidth={476}
        contentDivHeight={300}
        labelType={LabelType.BODY3_G4}
        labelTypeHighlighted={LabelType.BODY3}
        chevdownIconHighlightColor={ColorType.GREY6}
        headerHighLightClassName='bg-grey1 border-grey3 rounded'
      />
    </div>
  );
};

export default React.memo(SelectCharger);
